import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  // dashboard
  @Output() alertOpen : EventEmitter<any> = new EventEmitter()

  @Output() modalOpen : EventEmitter<any> = new EventEmitter()

  // company
  @Output() detailsCommerceOpen: EventEmitter<any> = new EventEmitter()

  @Output() detailsCommerceClose : EventEmitter<any> = new EventEmitter()

  // user
  @Output() detailsUserOpen : EventEmitter<any> = new EventEmitter()

  @Output() detailsUserClose : EventEmitter<any> = new EventEmitter()

  // promotion
  @Output() detailsPromotionOpen : EventEmitter<any> = new EventEmitter()

  @Output() detailsPromotionClose : EventEmitter<any> = new EventEmitter()

  // services
  @Output() detailsServiceOpen : EventEmitter<any> = new EventEmitter()

  @Output() detailsServiceClose : EventEmitter<any> = new EventEmitter()

  // product
  @Output() detailsProductOpen : EventEmitter<any> = new EventEmitter()

  @Output() detailsProductClose : EventEmitter<any> = new EventEmitter()

  // orders
  @Output() detailsOrderOpen : EventEmitter<any> = new EventEmitter()

  @Output() detailsOrderClose : EventEmitter<any> = new EventEmitter()

  // notification
  @Output() detailsNotificationOpen : EventEmitter<any> = new EventEmitter()

  @Output() detailsNotificationClose : EventEmitter<any> = new EventEmitter()

  // vehicle maker
  @Output() addVehicleMakerOpen : EventEmitter<any> = new EventEmitter()

  @Output() addVehicleMakerClose : EventEmitter<any> = new EventEmitter()

   // vehicle model
   @Output() addVehicleModelOpen : EventEmitter<any> = new EventEmitter()

   @Output() addVehicleModelClose : EventEmitter<any> = new EventEmitter()


  constructor(){}
}
