import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/app/environment/environment';
import { loginResponse, userLogin } from 'src/app/login/interface';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AlertsService } from './alerts.service';

const helper = new JwtHelperService()

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private URLBackend = environment.URLBack
  private loggeIn = new BehaviorSubject<boolean>(false)
  
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _alertService : AlertsService
  ) { 
    this.checkTokenAuth()
  }

  public logiIn(form: userLogin):Observable<loginResponse> {
    return this._http.post<loginResponse>(`${this.URLBackend}/manage/login`,{
      "email": form.email,
      "password": form.password
    }).pipe(
      map((resp:loginResponse)=> {               
        if(resp.status == true) { 
          this.saveToken(resp.data.token)
          this.loggeIn.next(true)
          this._alertService.alertOpen.emit({
            data: { text: resp.message, type: 'success'}
          })
          setTimeout(() => {
            this._router.navigate(['dashboard'])       
           }, 500); 
        }else{
          this._alertService.alertOpen.emit({
            data: { text: resp.message, type: 'danger'}
          })
        }
        return resp
      }),
      catchError(err => {
        this._alertService.alertOpen.emit({
          data: { text: err.message? err.message : 'A ocurrido un error, intente mas tarde.', type: 'danger'}
        })
        return  throwError(err)
      })
    )
  }

  public saveToken(token: string){
    localStorage.setItem('token',token)
  }

  get isLogged():Observable<boolean>{
    return this.loggeIn.asObservable()
  }

  checkTokenAuth(){
    const tokenUser = localStorage.getItem('token')
    const expire = helper.isTokenExpired(tokenUser)
    expire ? this.logOut() : this.loggeIn.next(true) 
  }

  public logOut(){
    this.loggeIn.next(false)
    localStorage.removeItem('token')
    this._router.navigate(['login'])
  }

  get readToken(){
    return localStorage.getItem('token')
  }

 

}
