import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsService } from 'src/service/alerts.service';
import { LoginService } from 'src/service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public formUsername : FormGroup
  public hide: boolean = true
  public alerts: any = []

  constructor(
    private _builder: FormBuilder,
    private _loginService: LoginService,
    private _alertService : AlertsService
  ){    
    this.formUsername = this._builder.group({
      username: ['',Validators.required],
      password: ['', Validators.required]
    })


    this._alertService.alertOpen.subscribe(m => {      
      m.data ? this.alerts.unshift(m.data) : this.alerts = []    
    })
  }

  async onSubmit(){
    this._loginService.logiIn({ email: this.formUsername.value.username, password: this.formUsername.value.password }).subscribe(resp => {})
  }
}
