<div class="main-content w-100 h-100">
  <clr-alerts>
    <clr-alert [clrAlertType]="alert?.type" [clrAlertAppLevel]="true" *ngFor="let alert of alerts">
      <clr-alert-item >
          <span class="alert-text">
              {{alert?.text}}
          </span>
      </clr-alert-item>
    </clr-alert>
  </clr-alerts>
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center" style="height: 100vh;">
          <div class="col-10 col-sm-4">
            <mat-card class="p-3">
              <mat-card-header class="p-0 d-flex flex-column justify-content-center">
                <div class="text-center">
                  <img src="assets/images/Logo_Ruedalo.svg" class="img-fluid w-25" alt="">
                </div>
                <div class="text-center pt-2">
                  <h4 class="my-auto"><strong style="font-weight: 500;">Sesion Admin</strong></h4>
                </div>
              </mat-card-header>
              <mat-card-content class="pt-3">
                <form [formGroup]="formUsername">
                  <mat-form-field>
                    <mat-label>Username:</mat-label>
                    <input matInput placeholder="Username" formControlName="username" type="text" required>
                    <mat-icon matSuffix> email </mat-icon>
                    <mat-error *ngIf="this.formUsername.get('username')?.hasError('required')">Username requerido</mat-error>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>password:</mat-label>
                    <input matInput placeholder="Password" formControlName="password" [type]="hide ? 'password' : 'text'" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="this.formUsername.get('password')?.hasError('required')">Password requerido</mat-error>
                  </mat-form-field>

                  <div class="row justify-content-center px-3 pt-3">
                    <button mat-raised-button color="primary" [disabled]="formUsername.invalid" (click)="onSubmit()">
                      Ingresar
                    </button>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
    </div>
  </div>